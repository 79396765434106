/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');
window.Vue = require('vue');
import { Icon }  from 'leaflet'
import Buefy from 'buefy'

window.moment = require('moment');

Vue.use(Buefy)

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: '',
  iconSize: [48, 48],
});
window.ProductMapComponent    = require('./components/ProductMapComponent.vue').default;
window.MapComponent           = require('./components/MapComponent.vue').default;
window.CalendarComponent      = require('./components/CalendarComponent.vue').default;
window.MultiselectComponent   = require('./components/MultiSelectComponent.vue').default;
window.GuestsSelectComponent  = require('./components/GuestsSelectComponent.vue').default;
window.VueNumberInput         = require('@chenfengyuan/vue-number-input');
window.HomediningComponent    = require('./components/HomediningBookingComponent.vue').default;
window.ProductComponent       = require('./components/ProductBookingComponent.vue').default;
window.CarouselComponent      = require('./components/ProductCarouselComponent.vue').default;
window.RatingComponent        = require('./components/RatingComponent.vue').default;
window.VueCountrySelect       = require('vue-country-select');
<!-- Vue component -->
<template>
  <div class="guests-select d-none d-sm-none d-md-block">
    <strong>{{title}}</strong>
    <multiselect v-model="value" :options="options" :searchable="false" :close-on-select="true" :show-labels="false" :hideSelected="true" placeholder="Guests" :preselect-first="true" @input="change"></multiselect>
    <input type="hidden" name="guests" :value="guests">
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'

  export default {
      components: {
        Multiselect
    },
    props: ['title'],
    data () {
      return {
        value: [],
        options: [],
        guests: 1
      }
    },
    methods: {
        change (input, id) {
          this.guests = this.value;
        }
    },
    mounted: function() {
      let url = new URL(window.location.href)
      for(let i = 1; i <= 50; i++) {
        this.options.push(i);
      }
      this.value = (url.searchParams.get('guests')) ? url.searchParams.get('guests') : 1;
    }
  }
</script>
<template>
  <div class="column is-paddingless map-container">
    <l-map
      style="height: 400px; width: 100%; z-index: 0;"
      :zoom="zoom"
      :center="center"
      :max-zoom="max"
      :options="{zoomControl: false}"
    >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-circle
        :lat-lng="location.center"
        :radius="location.radius"
        :color="location.color"
      />
    </l-map>
  </div>
</template>
<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LCircle
} from "vue2-leaflet";

export default {
	name: "ProductLocation",
	props: ["latitude","longitude"],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LCircle
  },
  data() {
    return {
      gettingLocation: true,
      zoom: 13,
      max: 13,
			center: L.latLng(62.016321,-6.765673),
			location: {
          center: [],
          radius: 300,
          color: 'green'
      },
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      iconSize: [48, 48],
    }
	},
	created() {
    this.center = L.latLng(this.latitude, this.longitude);
		this.location.center = [this.latitude, this.longitude];
	}
};
</script>
<template>
    <section>
        <b-rate 
            v-model="rating"
            :icon-pack="packs"
            :icon="icons" 
            :max="max" 
            :size="textsize"
            :show-score="score"
            :custom-text="reviews"
            :rtl="isRtl"
            :spaced="isSpaced"
            :disabled="isDisabled">
        </b-rate>
    </section>
</template>
<script>
export default {
    props: {
        max: {
            type: Number,
            default: 5
        },
        rating: {
            type: Number,
            default: 0
        },
        reviews: {
            type: String,
            default: ''
        },
        textsize: {
            type: String,
            default: 'is-size-6'
        }
    },
    data() {
            return {
                packs: 'fa',
                icons: 'star',
                score: false,
                isRtl:false,
                isSpaced: false,
                isDisabled: true
            }
        }
}
</script>
<template>
  <b-carousel @click="goTo()"
    :icon-pack="iconPack"
    :arrow="arrow"
    :repeat="repeat"
    :has-drag="drag"
    :autoplay="autoPlay"
    :pause-hover="pauseHover"
    :pause-info="pauseInfo"
    :indicator="indicator"
    :indicator-background="indicatorBackground"
    :indicator-inside="indicatorInside"
    :indicator-mode="indicatorMode"
    :indicator-position="indicatorPosition"
    :indicator-style="indicatorStyle"
    >
      <b-carousel-item v-for="(item, i) in items" :key="i">
        <figure class="image lazy is-4by3">
          <img :src="item" />
        </figure>
      </b-carousel-item>
  </b-carousel>
</template>

<script>
  export default {
    props: {
      items: {
        type: Array,
        default: [],
      },
      indicator: {
        type: Boolean,
        default: false
      },
      autoPlay: {
        type: Boolean,
        default: false
      },
      link: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        arrow: true,
        iconPack: 'fa',
        arrowHover: true,
        drag: true,
        gallery: false,
        perList: 1,
        pauseHover: true,
        pauseInfo: false,
        repeat: false,
        indicatorBackground: false,
        indicatorInside: false,
        indicatorMode: 'hover',
        indicatorPosition: 'is-bottom',
        indicatorStyle: 'is-lines',
      };
    },
    methods: {
      goTo() {
        if(this.link) {
          location.href = this.link;
        }
      }
    }
  };
</script>
<template>
    <div class="date-picker is-flex is-flex-direction-row">
      <b-field>
        <b-datepicker
            :placeholder="title"
            v-model='selectedDates'
            :min-date="minDate"
            :max-date='maxDate'
            :date-formatter="formatDate"
            :first-day-of-week="1"
            :mobile-native="false"
            @range-start="setEndDate"
            @range-end="setOpenCalendar"
            range
            icon-pack="fa"
        >
        </b-datepicker>
        <p class="control">
          <button class="button" type="submit">{{ search }}</button>
        </p>
      </b-field>
      <input type="hidden" name="start_date" :value="selectedDates[0].getTime()">
      <input type="hidden" name="end_date" :value="selectedDates[1].getTime()">
    </div>
</template>

<script>
var today = new Date();
today.setDate(today.getDate() + 1);

export default {
  props: ['title','search'],
  data() {
    return {
      selectedDates: [today,new Date(today.getFullYear(), today.getMonth(), today.getDate() + 5)],
      minDate: today,
      maxDate: new Date(today.getFullYear()+1, today.getMonth(), today.getDate())
    }
  },
  methods: {
    formatDate(dt) {
      let from = moment(dt[0]);
      let to = moment(dt[1]);
      return from.format('DD.  MMMM') + ' - ' +  to.format('DD. MMMM');
    },
    setEndDate(dt) {
      let max = new Date(dt);
      max.setDate(max.getDate() + 21);
      this.maxDate = max;
    },
    setOpenCalendar(dt) {
      let max = new Date(dt);
      max.setDate(max.getDate() + 356);
      this.maxDate = max;
    }
  },
  created: function() {
      let url = new URL(window.location.href);
      if(url.searchParams.get('start_date') && url.searchParams.get('end_date')) {
        let from_date = url.searchParams.get('start_date');
        let to_date = url.searchParams.get('end_date');
        this.selectedDates[0] = new Date(parseInt(from_date));
        this.selectedDates[1] = new Date(parseInt(to_date));
        return;
      }
  }
};
</script>
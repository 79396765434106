<template>
<div class="columns is-map-wrapper is-marginless is-paddingless">
  <div class="column is-one-fifth-desktop is-full p-0 has-background-light left">
    <b-datepicker
          placeholder="Click to select..."
          v-model='selectedDates'
          :min-date="minDate"
          :max-date='maxDate'
          :mobile-native="false"
          range
          :date-formatter="formatDate"
          icon="calendar-alt"
          icon-pack="fa"
      >
    </b-datepicker>
    <div class="dates">
      <ul class="items">
        <li class="item columns is-mobile" v-for="(item, index) in products" v-bind:key="index" @click="selectProduct(item)">
          <div class="column is-one-third-mobile is-one-quarter-mobile is-flex is-flex-direction-column is-justify-content-center is-hidden-tablet">
            <b-image
              :src="item.image[0]"
              :alt="item.name"
              ratio="1by1"
              :rounded="false"
              :lazy="true"
              custom-class="is-92x92 p-2 rounded"
            ></b-image>
          </div>
          <div class="column pl-3 description">
            <h2>{{item.name}}</h2>
            {{ item.area.join(', ') }}, {{item.price}} kr.<br>
            <span class="tag is-info">{{ item.date }} {{item.time}}</span>
          </div>
          <div class="column is-flex is-flex-direction-column is-justify-content-center is-two-thirds-mobile is-one-fifth-tablet is-one-quarter-desktop is-hidden-mobile">
            <b-image
              :src="item.image[0]"
              :alt="item.name"
              ratio="1by1"
              :rounded="false"
              :lazy="true"
              custom-class="is-92x92 p-2 rounded"
            ></b-image>
          </div>
        </li>
      </ul>
  </div>
  </div>
  <div class="column map-container mx-0 is-hidden-mobile">
    <l-map style="height: 100%; width: 100%; z-index: 0" :zoom="zoom" :center="center"
      @update:bounds="boundsUpdated"
      @ready="ready"
    >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-marker v-if="location.length" :lat-lng="location">
        <l-icon
          :icon-size="dynamicSize"
          icon-url="/images/vendor/leaflet/dist/marker-where-am-i.svg"
        ></l-icon>
        <l-tooltip :options="options" class="has-text-weight-semibold">Your location</l-tooltip>
      </l-marker>
      <l-marker v-for="(marker, index) in markers" 
          :key="index"
          :lat-lng="marker.position"
          @click="selectProduct(marker)">
          <l-icon
            :icon-size="dynamicSize"
            icon-url="/images/vendor/leaflet/dist/marker-me-icon.svg"
          ></l-icon>
          <l-tooltip :options="options" class="lead"><span class="is-family-primary has-text-weight-bold">{{marker.name}}</span><br><span class="">{{marker.price}}</span></l-tooltip>
        </l-marker>
    </l-map>
  </div>
</div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LIcon, LPopup, LTooltip, LCircle } from 'vue2-leaflet';
var today = new Date();
today.setDate(today.getDate() + 2);
var dateoptions = { year: 'numeric', month: 'short', day: 'numeric' };

const EventModal = {
    props: ['item','title','date','dates'],
    template: `
        <section class="hero has-background-white" id="product-description">
        <carousel-component :items="item.image" :indicator="false" :auto-play="false"></carousel-component>
        <div class="hero-body">
          <div class="container">
            <div class="level is-mobile"><span v-for="date in dates" v-html="date"></span></div>
            <h1 class="title" v-html="title"></h1>
            <h2 class="subtitle">
              {{date}} {{item.time}}, {{item.price}} kr.
            </h2>
            <div class="text" v-html="item.information"></div>
          </div>
        </div>
      </section>
    `
}

export default {
    name: 'MapSearch',
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LIcon,
        LPopup,
        LTooltip,
        LCircle,
        EventModal
    },
    props: {
      start: {
        type: Number,
        required: false,
        default: today.getTime()
      },
      end: {
        type: Number,
        required: false,
        default: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 5).getTime()
      }
    },
    data() {
    return {
      gettingLocation: true,
      loadingLocations: null,
      selectedDates: [],
      minDate: today,
      maxDate: new Date(today.getFullYear()+1, today.getMonth(), today.getDate()),
      zoom: 9,
      radius: 200,
      location: [],
      center: L.latLng(62.016321,-6.765673),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      markers: new Set(),
      options: { permanent: false, direction: "auto", sticky: true },
      products: [],
      iconSize: [18, 18],
      bounds: null,
      isComponentModalActive: false
    }
  },
  watch: {
    selectedDates: function(old, dates) {
      if (old !== dates ) {
        this.loadProducts();
        this.updateQueryString();
      }
    }
  },
  computed: {
    dynamicSize () {
      return [this.iconSize, this.iconSize];
    },
    dynamicAnchor () {
      return [this.iconSize / 2, this.iconSize];
    }
  },
  methods: {
    showToast(message) {
        this.loadingLocations = this.$buefy.toast.open({
            indefinite: true,
            position: 'is-top',
            message: `${message}`,
            type: 'is-info'
        })
    },
    closeToast() {
        if (this.loadingLocations) {
            this.loadingLocations.close();
            this.loadingLocations = null;
        }
    },
    formatDate(dt) {
      let from = moment(dt[0]);
      let to = moment(dt[1]);
      return from.format('DD.  MMMM') + ' - ' +  to.format('DD. MMMM');
    },
    loadProducts: _.debounce(function (e) {
      var dates = [];
      this.showToast('Getting result ...', { indefinite: true, position: 'is-top', type: 'is-info' });

      let start = this.selectedDates[0].getTime();
      let end = this.selectedDates[1].getTime();

      let query = {};
      if (screen.width > 1024) {
        query = {
          start_date: start,
          end_date: end,
          northeast: this.bounds._northEast,
          southwest: this.bounds._southWest
        }
      } else {
        query = {
          start_date: start,
          end_date: end
        }
      }

      axios.post("/api/products/map", query).then(response => {
        response.data.forEach(element => {
          dates.push(element);
        });
        const markers = Array.from(new Set(dates.map(s => s.id)))
          .map(id => {
            return {
              id: id,
              name: dates.find(s => s.id === id).name,
              information: dates.find(s => s.id === id).information,
              image: dates.find(s => s.id === id).image,
              price: dates.find(s => s.id === id).price,
              time: dates.find(s => s.id === id).time,
              area: dates.find(s => s.id === id).area,
              position: dates.find(s => s.id === id).position,
              visible: true
            }
          });
        this.markers = markers;
        this.products = dates;
        this.closeToast();
      });
    }, 900),
    selectProduct(selected) {
      this.eventModal(selected);
    },
    getDatesForProduct(id) {
      let calendar =  this.products.filter(function(product) {
        return product.id === id;
      });
      return calendar;
    },
    eventModal(item) {
      let date = '';
      let title = item.name;
      if(item.raw_date !== undefined) {
        date = moment(item.raw_date.date).format('dddd, DD-MM');
        title = '<a href="/product/'+item.slug+'?date='+moment(item.raw_date.date).format('YYYY-MM-DD')+'">'+item.name+'</a>';
      }
      let dates = [];
      this.getDatesForProduct(item.id).forEach(value => {
        let active = (item.date == value.date ) ? ' is-active' : '';
        dates.push('<a href="/product/'+value.slug+'?date='+moment(value.raw_date.date).format('YYYY-MM-DD')+'" class="level-item is-unselectable'+active+'"><div><p class="heading">'+moment(value.raw_date.date).format('ddd')+'</p><p class="title">'+value.date+'</p></div></a>');
      });
      this.$buefy.modal.open({
          parent: this,
          component: EventModal,
          hasModalCard: false,
          fullScreen: screen.width >= 1024 ? false : true,
          canCancel: true,
          trapFocus: true,
          width: 640,
          props: {
            item: item,
            title: title,
            date: date,
            dates: dates
          }
      });
      console.log(this.$buefy.modal);
    },
    ready (obj) {
      this.bounds = obj.getBounds();
    },
    boundsUpdated (bounds) {
      this.bounds = bounds;
      this.loadProducts();
    },
    updateQueryString () {
      var url = new URL(window.location.href);
      var searchParams = new URLSearchParams(window.location.search);
      searchParams.set('start_date', this.selectedDates[0].getTime())
      searchParams.set('end_date', this.selectedDates[1].getTime());
      url.search = searchParams;
      url = url.toString();
      window.history.replaceState({url: url}, null, url);
    }
  },
  created() {
    if (this.start !== '') {
      this.selectedDates = [new Date(this.start), new Date(this.end)];
    }
    if(!("geolocation" in navigator)) {
      this.errorStr = 'Geolocation is not available.';
      this.showToast('Geolocation is disabled.', { indefinite: true, position: 'is-top', type: 'is-danger' });
      return;
    }
    this.showToast('Getting get location ...', { indefinite: true, position: 'is-top', type: 'is-info' });
    navigator.geolocation.getCurrentPosition(pos => {
      this.location = [pos.coords.latitude,pos.coords.longitude];
      this.center = L.latLng(pos.coords.latitude, pos.coords.longitude);

      this.loadProducts();
    }, err => {
      this.showToast('Cannot get location, setting default.', { indefinite: true, position: 'is-top', type: 'is-info' });
      this.location = [62.016321,-6.765673];
      this.center = L.latLng(62.016321,-6.765673);
      this.loadProducts();
      this.errorStr = err.message;
    });
    // document.addEventListener('backbutton', function(){
    //   if(menu is visible) {
    //       //Hide the menu
    //       //This is also working fine
    //   return false;
    //   }
    //   else //nothing is visible, exit the app
    //   {
    //     navigator.app.exitApp();
    //   }
    // });
  }
}
</script>
<!-- Vue component -->
<template>
  <div class="area-select is-hidden-touch is-half-desktop">
    <multiselect v-model="value" :options="options" group-values="locations" group-label="area" :group-select="true" :limit="3" :hideSelected="true" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="false" :searchable="true" :placeholder="placeholder" label="name" track-by="name" :preselect-first="false" @input="change"></multiselect>
    <input type="hidden" name="area" :value="area">
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
  export default {
      components: {
        Multiselect
    },
    props: ['title','placeholder','selectedText','country'],
    data () {
      return {
        value: [],
        options: [],
        area: null
      }
    },
    methods: {
        change (input, id) {
          let area = [];
          this.value.forEach(element => {
            area.push(element.name);
          });
          this.area = area;
        },
        async requestData() {
          try {
            const { data } = await axios.post("/api/areas/" + this.country, {});
            localStorage.setItem('areas', JSON.stringify(data));
            this.options = data;
          } catch (e) {
            localStorage.removeItem('areas');
            this.options = [];
            console.log(e);
          }
        }
    },
    mounted() {
      let url = new URL(window.location.href);
      let selected = (url.searchParams.get('area')) ? url.searchParams.get('area').split(",") : [];
      this.area = url.searchParams.get('area');
      selected.forEach(element => {
        this.value.push({ name: element });
      });
    },
    created() {
      if(localStorage.getItem('areas')) {
        this.options = JSON.parse(localStorage.getItem('areas'));
      } else {
        this.requestData();
      }
    }

  }
</script>